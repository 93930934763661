import React, { useEffect, useState, useRef } from "react";
import devon from "./devon.png";
import devonAudio from "./devon.mp3";
import "./App.css";

interface SpawningDevon {
  id: number;
  x: number;
  y: number;
  angle: number;
  scale: number;
}

function App() {
  const [angle, setAngle] = useState(0);
  const ROTATION_SPEED = 0.5;
  const containerRef = useRef<HTMLDivElement>(null);
  const audioRef = useRef<HTMLAudioElement>(null);
  const [speed, setSpeed] = useState(ROTATION_SPEED);
  const [spawningDevons, setSpawningDevons] = useState<SpawningDevon[]>([]);
  const nextIdRef = useRef(0);

  useEffect(() => {
    const animate = () => {
      setAngle((prev) => prev + speed);
      requestAnimationFrame(animate);
    };

    requestAnimationFrame(animate);
  }, [speed]);

  useEffect(() => {
    // Create stars dynamically
    const starsContainer = document.querySelector(".stars-container");
    if (starsContainer) {
      for (let i = 0; i < 100; i++) {
        const star = document.createElement("div");
        star.className = "star";
        star.style.left = `${Math.random() * 100}%`;
        star.style.top = `${Math.random() * 100}%`;
        star.style.animationDelay = `${Math.random() * 5}s`;
        starsContainer.appendChild(star);
      }
    }
  }, []);

  const handleClick = (e: React.MouseEvent) => {
    e.stopPropagation();
    if (audioRef.current) {
      audioRef.current.play();
      audioRef.current.loop = true;
    }
  };

  const spawnDevon = () => {
    const newDevon: SpawningDevon = {
      id: nextIdRef.current++,
      x: Math.random() * 100,
      y: Math.random() * 100,
      angle: Math.random() * 360,
      scale: 0,
    };

    setSpawningDevons((prev) => [...prev, newDevon]);

    // Remove the Devon after animation
    setTimeout(() => {
      setSpawningDevons((prev) =>
        prev.filter((devon) => devon.id !== newDevon.id)
      );
    }, 2000); // 2 seconds total duration
  };

  const handleDevonClick = (e: React.MouseEvent) => {
    e.stopPropagation();
    setSpeed((prev) => prev * 1.5);
    if (audioRef.current) {
      audioRef.current.play();
      audioRef.current.loop = true;
    }
    // Spawn 3-5 new Devons
    const spawnCount = Math.floor(Math.random() * 3) + 3;
    for (let i = 0; i < spawnCount; i++) {
      spawnDevon();
    }
  };

  return (
    <div className="App" ref={containerRef} onClick={handleClick}>
      <div className="stars-container" />
      <audio ref={audioRef} src={devonAudio} />
      {spawningDevons.map((spawnedDevon) => (
        <div
          key={spawnedDevon.id}
          className="spawned-devon"
          style={{
            position: "absolute",
            left: `${spawnedDevon.x}%`,
            top: `${spawnedDevon.y}%`,
            transform: `rotate(${spawnedDevon.angle}deg)`,
          }}
        >
          <img src={devon} alt="Spawned Devon" />
        </div>
      ))}
      <div
        className="spinning-container"
        onClick={handleDevonClick}
        style={{
          position: "absolute",
          left: "50%",
          top: "50%",
          transform: `translate(-50%, -50%) rotate(${angle}deg)`,
        }}
      >
        <img src={devon} alt="Spinning Devon" />
      </div>
      <button
        className="donate-button"
        onClick={() =>
          window.open("https://us.movember.com/donate/details?memberId=97#amount", "_blank")
        }
      >
        Donate
      </button>
    </div>
  );
}

export default App;
